section{
    width: 100%;
    min-height: 85vh;
    background-color: white;
    display: flex;
    flex-direction: row;   
    margin:4rem 0 4rem;
}
.stage {    
    width: 60vw;
    height: 100%;
    position: relative;
    margin: 0 2rem 0 2rem;
}

.controls{
    border-top: 10pt solid grey;
    padding-top: 1.5rem;
    width: 30vw;
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    font-family: 'Assistant', sans-serif;
}
.controls h1{
    margin-bottom:1.5rem;
    word-break: break-word;
    font-size: 4rem;
}
.control_item{
    margin-bottom: 2rem;
    display:flex;
    flex-direction: column;
}
.control_item input{
    font-family: 'Assistant', sans-serif;
    font-size: 14pt;
    padding:1rem;
    border:1pt solid #999
}
.price_control{
    padding: 2rem;
    display: flex;
    gap: 2rem;
    flex-direction: row-reverse;
    align-items: center;
    border:1pt solid grey;
    justify-content: space-between;
}
select.control_item {
    font-family: 'Assistant', sans-serif;
    font-size: 14pt;
    padding:1rem;
    border:1pt solid #999
}
.quantity label{
    font-size: smaller;
}
.quantity_box{
    display: flex;
    flex-direction: row;
    border:1pt solid #333;
    align-items: center;
    width:max-content;   
}
.color_boxes{
    display: flex;
    flex-direction: row; 
    align-items: center;
    width:max-content;   
}
.box{
    border:1pt solid #666;
    border-radius: 10px;
    margin-right: 1rem;
    padding:0.5rem 1rem 0.5rem 1rem;
    font-size: 14pt;
    cursor: pointer;
}
.textBox{
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
}
.ln1{
    font-weight: 600;
    font-size: 18pt;
}
.ln2{
    font-weight: 400;
    font-size: 14pt;
}
.ln1,.ln2{
    text-align: center;
    width: 100%;
    border:1pt solid blue;
}
.add, .minus{
    cursor: pointer;
    font-size: 18pt;
    padding:0 2rem 0 2rem;
    
}
.add_to_cart{
    margin:2rem 0;
    cursor: pointer;
}
.add_to_cart_btn{
    cursor: pointer;
    font-family: 'Assistant', sans-serif;
    font-size: 15pt;
    padding:2rem;
    border:1pt solid #999;
    background-color: green;
    color: white;
}
.value{
    font-size: 16pt;
    padding:0 2rem 0 2rem;
}
.overlay{
    position: absolute;
}
@media (max-width:768px) {
    section{
        flex-direction: column;
        padding: 0;
        margin:0;
    }
    .stage {    
        width: 100%;
        height: 100%;
       margin:0;
    }
    .controls{        
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 2rem 0 2rem;
        font-family: 'Assistant', sans-serif;
    }
}