nav{
    width: 100%;
    min-height: 12vh;
    background-color: rgb(241,241,241);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 6rem 0 6rem;
}
.branding{
    font-family: 'Architects Daughter', cursive;
    font-size: 34pt;
    font-weight:700;
    color:black;
    margin:2rem;
    align-self: flex-start;
}
.navlinks ul{
    display: flex;
    flex-direction: row;
    list-style: none;
    margin-left: 4rem;
}
.navlinks ul>li{
    margin: 0 2rem 0 2rem;
    font-size: 14pt;
    font-family: 'Assistant', sans-serif;
}
.navlinks ul>select{
    font-size: 14pt;
    border: none;
    background-color: transparent;
    font-family: 'Assistant', sans-serif;
}
.hamburger_menu{
    display:none;
    flex-direction: column;
    margin-left: auto;
    cursor: pointer;
}
.mobile_links{
    display: none;
    flex-direction: row;
    background-color: #333;
    
}
.mobile_links ul{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.mobile_links ul> li{
    color:bisque;
    list-style: none;
    margin-right: 1.5rem;
}
.mobile_links select{
    margin-right: 1.5rem;
}
.mobile_links select,
.mobile_links select> option{
    color:bisque;
    background-color: #333;
    border:none;
}
.h_line{
    
        width: 15px;
        height: 1px;
        background-color: black;
        margin: 3px 0;
      
}
@media (max-width:768px){
    
    .branding{
        margin: 0;
        font-size: 24pt;
        align-self: center;
    }
    nav{
        padding:0 1rem 0 1rem;
        width:100%;
        border:1pt solid red;
    }
    .navlinks{
        display: none;
    }
    .hamburger_menu{
        display: flex;
    }
}